import React, { useState } from "react"
import Logo from "../../img/buy-neosurf-logo-header.png"
import { Link } from "gatsby"

export default function Navbar() {
  const [isHamburgerClicked, setIsHamburgerClicked] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  function handleNavBtnClick() {
    setIsHamburgerClicked(prevState => {
      console.log(prevState)
      return !prevState
    })
  }

  const mobileNavBarStyle = {
    left: `${isHamburgerClicked ? "10px" : "10000px"}`,
  }

  return (
    <div className="navbar">
      <div className="white-bg" />
      <img className="navbar-logo" src={Logo} alt="Buyneosurf logo" />
      <ul className="navbar-links" style={mobileNavBarStyle}>
        <li className="unclickable">
          <span>Where to purchase</span>
        </li>
        <li className="unclickable">
          <span>Neosurf worldwide</span>
        </li>
        <li className="unclickable">
          <span>Neosurf casinos</span>
        </li>
        <li className="unclickable">
          <span>FAQ</span>
        </li>
        <li className="unclickable">
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="articles">
            <small className="blog-button">Blog </small>
          </Link>
        </li>
      </ul>
      <div className="menu-icon" onClick={handleNavBtnClick}>
        <div className="menu-icon-part"></div>
        <div className="menu-icon-part"></div>
        <div className="menu-icon-part"></div>
      </div>
    </div>
  )
}
